/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext, useState } from 'react';
import { type ContentStackButton } from '../../interface';
import { Button, View, Text, type ButtonProps, type ViewProps } from '@az/starc-ui';
import { CMSLink } from '../CMSLink/CMSLink';
import styles from './CMSButton.module.scss';
import type { TabIndexes } from '@/components/SignInModalV2/interface';
import { countryCodes, defaultStoreUS } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useLocale } from '@/hooks/useLocale';
import { SignInFlowTypeContext } from '@/components/SignInFlowTypeContext';
import { isModalLink } from '../../utils/isModalLink';
import { useSelector } from 'react-redux';
import { getUserAuthenticated } from '@/constants/selectors';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';

const LazySignInModalV2 = dynamic(() => import('@/components/SignInModalV2'), {
  loading: () => <ProgressModal noScroll />,
});

const LazySignInModal = dynamic(() => import('@/components/SignInModal'), {
  loading: () => <ProgressModal noScroll />,
});

const LazySearchStoreModal = dynamic(
  () =>
    import('@/components/Store/StoreModal/SearchStoreModal').then((mod) => mod.SearchStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

const LazyMyStoreModal = dynamic(
  () => import('@/components/Store/StoreModal/MyStoreModal').then((mod) => mod.MyStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

type ButtonVariant = Exclude<
  Lowercase<NonNullable<ContentStackButton['theme']>>,
  'chevron' | 'pill' // TODO: remove `Exclude` after updating STARC version
>;

type Props = {
  options: ContentStackButton;
  size?: ButtonProps['size'];
  justify?: ViewProps<'div'>['justify'];
  ariaLabel?: string;
};

export function CMSButton({ justify = 'center', options, size, ariaLabel }: Props) {
  const isMxSite = useLocale() === countryCodes.mx;
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const toggleStoreModal = () => {
    setStoreModalOpen((s) => !s);
  };
  const { data: storeData } = useStoreDetailsData();
  const storeNumber = storeData?.storeNumber ?? defaultStoreUS;

  const isSignInV2Enabled = useFeatureFlag('ENABLE_STREAMLINE_SIGNIN') === 'true' && !isMxSite;

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<TabIndexes>(0);
  const { setFlowTypeFn } = useContext(SignInFlowTypeContext);
  const isLoggedIn = useSelector(getUserAuthenticated);

  const handleModalOpen = (id: string) => {
    switch (id) {
      case '[search_store_modal]':
        toggleStoreModal();
        break;
      case '[sign_in_modal]':
      case '[create_account_modal]':
        if (isLoggedIn) {
          break;
        }

        if (isSignInV2Enabled) {
          setFlowTypeFn('login');
        }
        setActiveTab(id === '[sign_in_modal]' ? 0 : 1);
        setOpen(true);
        break;
      case '[cookie_preference_center_modal]':
        window.OneTrust?.ToggleInfoDisplay();
        break;
    }
  };

  const isModalButton = isModalLink(options.link?.href ?? '');
  const isSignInModal =
    options.link?.href === '[sign_in_modal]' || options.link?.href === '[create_account_modal]';
  const isModalButtonDisabled = isSignInModal && isLoggedIn;
  const SignInModal = isSignInV2Enabled ? LazySignInModalV2 : LazySignInModal;
  const StoreModal = storeNumber !== defaultStoreUS ? LazyMyStoreModal : LazySearchStoreModal;
  return (
    <>
      <View justify={justify} direction="row">
        {isModalButton ? (
          <Button
            /* https://github.com/microsoft/TypeScript/issues/44268 */
            variant={(options.theme?.toLowerCase() as ButtonVariant | undefined) ?? 'primary'}
            className={styles.button}
            size={size}
            onClick={() => {
              handleModalOpen(options.link?.href ?? '');
            }}
            disabled={isModalButtonDisabled}
          >
            <Text size="087">{options.link?.title}</Text>
          </Button>
        ) : (
          <CMSLink to={options.link?.href ?? ''} title={ariaLabel ?? options.link?.title}>
            <Button
              /* https://github.com/microsoft/TypeScript/issues/44268 */
              variant={(options.theme?.toLowerCase() as ButtonVariant | undefined) ?? 'primary'}
              as="div"
              className={styles.button}
              size={size}
              tabIndex={-1}
            >
              <Text size="087">{options.link?.title}</Text>
            </Button>
          </CMSLink>
        )}
      </View>
      {isSignInModal && (
        <SignInModal
          isOpen={open}
          onClose={() => setOpen(false)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      {storeModalOpen && <StoreModal closeStoreModal={toggleStoreModal} />}
    </>
  );
}
