/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button } from '@/components/Button';
import ContentContainer from '@/components/AZCustomComponent/ContentContainer';
import { Grid } from '@/components/Grid';
import Hidden from '@/components/Hidden';
import NextImage from '@/components/NextImage';
import { SnackBarContext } from '@/components/SnackBarContext';
import { itemDetail } from '@/constants/images';
import { pageTypes } from '@/constants/page';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import { startTransition, useContext, useState } from 'react';
import { useDeleteDefaultVehicleMutation } from '../api/deleteDefaultVehicle';
import { setInteractionLocation } from '../utils/ymmeLocationHelper';
import { YMMEModal } from './YMMEModal';
import { YmmeSelectComponent } from './YMMESelectComp';
import HomePagePreferredVehicle from './HomePagePreferredVehicle';
import styles from './YMMEContentBlock.module.scss';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import type { VehicleSummary } from '@/types';
import { useProductList } from '@/features/shelf/context/useProductList';
import logger from '@/utils/logger';
import { useYmmeDefaultData } from '../utils/useYmmeDefaultData';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { eventConstants } from '@/constants/event';
import { trackShopWithoutVehicle } from '@/utils/analytics/track/trackShopWithoutVehicle';
import SlimMobileInlineYMME from './SlimMobileInlineYMME';
import { usePageType } from '@/hooks/usePageType';
import { usePageType as usePageTypeData } from '@/features/category/api/getPageType';
import { countryCodes } from '@/constants/locale';
import { useRouter } from 'next/router';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const vehicleIconOrange = {
  src: '/images/vehicle-new-orange.svg',
  alt: '',
};

export type YMMEContentBlockProps = {
  data?: unknown;
  pageType?: string;
  onUpdateVehicleSuccess?: () => void;
  ymmeTitle?: string | React.ReactNode;
  ymmeDesc?: string | React.ReactNode;
  layoutType?: 'thin' | 'regular';
  classes?: {
    titleContainerCustomStyle?: string;
    ymmeTitleLabel?: string;
    ymmeDescLabel?: string;
  };
  removeBottomMargin?: boolean;
  removeMargins?: boolean;
  withPadding?: boolean;
  isProductFinderDisplayed?: boolean;
};

export const YMMEContentBlock = ({
  data,
  pageType = '',
  onUpdateVehicleSuccess,
  layoutType,
  ymmeTitle,
  ymmeDesc,
  classes = {},
  removeBottomMargin,
  removeMargins = false,
  withPadding = true,
  isProductFinderDisplayed = false,
}: YMMEContentBlockProps) => {
  const { showSnackBar } = useContext(SnackBarContext);
  const { data: headerData } = useHeaderData();
  const router = useRouter();
  const { data: pageTypeData } = usePageTypeData();
  const vehicle = getPreferredVehicle(headerData);
  const { pageType: pageTypeFromHook } = usePageType();
  const yourLabel = useLabel('label_MyAccountMyProfile_landing_RewardYour');
  const hasBeenMovedLabel = useLabel('label_YMME_Body_HasBeenMovedTo');
  const [showYMMEMobileEnable, setShowYMMEMobileEnable] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const { data: shelfData } = useProductList();
  const { data: ymmeData } = useYmmeDefaultData();
  const productFinderData = shelfData?.productFinderData;
  const productFilterData = shelfData?.productFilterData;
  const { defaultEngine } = ymmeData;
  const locale = useLocale();
  const isPDP = router.route === '/products/[...slug]';

  const { mutateAsync: removeDefaultVehicle } = useDeleteDefaultVehicleMutation();
  const isShelfOrSearchPage =
    pageTypeFromHook === pageTypes.shelf ||
    pageTypeFromHook === pageTypes.ProductShelf ||
    pageTypeFromHook === pageTypes.search ||
    pageTypeFromHook === pageTypes.MerchShelf;
  const isUS = locale === countryCodes.us;
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') &&
    isMobile &&
    isShelfOrSearchPage &&
    isUS &&
    !removeBottomMargin;
  const makeOrModelName = [pageTypeData?.make, pageTypeData?.model].filter(Boolean).join(' ');
  //When shopbyURLsuffix has year ex: /batteries-starting-and-charging/battery/acura/ilx/2016 and /parts/acura/ilx/2016
  const shopByUrlSuffixHasYear =
    defaultEngine &&
    pageTypeData?.makeModelYearPath &&
    pageTypeData.makeModelYearPath.split('/').length >= 4;

  const productRefinementIsAfterYMME = productFinderData?.isAfterYmme || !!productFilterData;
  const searchPageContainer = '';

  const showYmmeWidget = () => {
    setInteractionLocation(pageType);
    startTransition(() => {
      setShowYMMEMobileEnable(true);
    });
  };

  const closeYmmeWidget = () => {
    setShowYMMEMobileEnable(false);
  };

  const handleShopWithoutVehicle = () => {
    try {
      setInteractionLocation(pageType);
      const snackMessage = vehicle?.vehicleName
        ? `${yourLabel} ${vehicle.vehicleName} ${hasBeenMovedLabel}`
        : '';
      showSnackBar(snackMessage);
      trackShopWithoutVehicle();
      void removeDefaultVehicle();
    } catch (err) {
      logger.error({
        message: 'Error when removing default vehicle',
        meta: {
          error: err,
        },
      });
    } finally {
      onUpdateVehicleSuccess?.();
    }
  };

  const currentShoppingVehicle = headerData?.vehicleMap?.vehicleName ?? '';

  const shouldShowCurrentShoppingVehicle = makeOrModelName
    ? shopByUrlSuffixHasYear // show currently shopping vehicle only on symm pages that have an auto selected vehicle ex /batteries-starting-and-charging/battery/acura/ilx/2016
    : currentShoppingVehicle;

  const isDataWithLayoutCode = (data: unknown): data is { layoutCode: string } => {
    return typeof data === 'object' && data !== null && 'layoutCode' in data;
  };

  const layoutCode = data
    ? isDataWithLayoutCode(data)
      ? data.layoutCode
      : undefined
    : layoutType
    ? layoutType
    : undefined;

  if (useNewShelfDesignMobile) {
    return (
      <SlimMobileInlineYMME
        showYmme={showYMMEMobileEnable}
        openYmmeWidget={showYmmeWidget}
        closeYmmeWidget={closeYmmeWidget}
        onUpdateVehicleSuccess={onUpdateVehicleSuccess}
      />
    );
  }

  if (
    layoutCode?.toLowerCase().includes('thin') &&
    shouldShowCurrentShoppingVehicle &&
    currentShoppingVehicle
  ) {
    return (
      <CurrentShopVehicleSelected
        vehicle={vehicle}
        currentShoppingVehicle={currentShoppingVehicle}
        showYmmeWidget={showYmmeWidget}
        onUpdateVehicleSuccess={onUpdateVehicleSuccess}
        closeYmmeWidget={closeYmmeWidget}
        handleShopWithoutVehicle={handleShopWithoutVehicle}
        productRefinementIsAfterYMME={productRefinementIsAfterYMME}
        showYMMEMobileEnable={showYMMEMobileEnable}
        removeBottomMargin={removeBottomMargin}
        removeMargins={removeMargins}
        classes={{
          searchPageContainer,
        }}
      />
    );
  }

  if (
    layoutCode?.toLowerCase().includes('thin') ||
    (isMobile && !shouldShowCurrentShoppingVehicle)
  ) {
    return (
      <CurrentShoppVehicleLayout
        ymmeTitle={ymmeTitle}
        ymmeDesc={ymmeDesc}
        pageType={pageType}
        makeOrModelName={makeOrModelName ?? null}
        showYmmeWidget={showYmmeWidget}
        onUpdateVehicleSuccess={onUpdateVehicleSuccess}
        closeYmmeWidget={closeYmmeWidget}
        productRefinementIsAfterYMME={productRefinementIsAfterYMME}
        showYMMEMobileEnable={showYMMEMobileEnable}
        removeMargins={removeMargins || (!!ymmeData && isProductFinderDisplayed)}
        classes={{
          ...classes,
          searchPageContainer,
        }}
      />
    );
  }

  return (
    <ContentContainer
      withPadding={withPadding}
      className={cx({
        [styles.ymmeContentContainer]: !removeMargins,
      })}
    >
      {!shouldShowCurrentShoppingVehicle ? (
        <div className={cx(styles.ymmeContainer, azCommonStyles['az-padding-bottom-s'])}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              className={` ${styles.ymmeTitle} ${styles.overridePadding} ${styles.alignCenter}`}
            >
              <h2
                data-testid="ymme-what-are-you-working-on-today"
                id="ymme-what-are-you-working-on-today"
                className={styles.ymmetitleTextStyles}
              >
                {ymmeTitle ? ymmeTitle : <Label label="label_YMME_Body_WhatAreYouWorkingOnToday" />}
              </h2>
            </Grid>
            <Grid
              item
              xs={12}
              className={cx(styles.ymmeTitleColor, styles.overridePadding, styles.alignCenter)}
            >
              <div className={cx(azCommonStyles['az-padding-bottom-xxs'], styles.ymmeSubTitle)}>
                {ymmeDesc ? ymmeDesc : <Label label="label_Add_your_vehicle_to_get_an_exact_fit" />}
              </div>
            </Grid>
            <Grid item xs={12} className={azCommonStyles['az-margin-bottom-xs']}>
              <Grid container justify="center">
                <Grid xs={12} lg={10}>
                  <YmmeSelectComponent
                    pageType={pageType}
                    showYmmeMobile={showYmmeWidget}
                    onUpdateVehicleSuccess={onUpdateVehicleSuccess}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div
          className={cx(styles.ymmeContainer, styles.ymmeContainerSelected, {
            [styles.ymmeContainerSelectedPDP]: isPDP,
          })}
        >
          <Grid item xs={12}>
            <HomePagePreferredVehicle
              pageType={pageType}
              removeDefaultVehicle={() => {
                try {
                  void removeDefaultVehicle();
                } catch (err) {
                  logger.error({
                    message: 'Error when removing default vehicle',
                    meta: {
                      error: err,
                    },
                  });
                } finally {
                  onUpdateVehicleSuccess?.();
                }
              }}
              showYmmeWidget={showYmmeWidget}
            />
          </Grid>
        </div>
      )}
      <Grid item xs={12}>
        <YMMEModal
          showYmme={showYMMEMobileEnable}
          closeYmmeWidget={closeYmmeWidget}
          onUpdateVehicleSuccess={onUpdateVehicleSuccess}
        />
      </Grid>
    </ContentContainer>
  );
};

type CurrentShopVehicleSelectedProps = {
  vehicle: VehicleSummary | undefined;
  currentShoppingVehicle: string;
  showYmmeWidget: () => void;
  onUpdateVehicleSuccess: (() => void) | undefined;
  closeYmmeWidget: () => void;
  handleShopWithoutVehicle: () => void;
  productRefinementIsAfterYMME: boolean;
  showYMMEMobileEnable: boolean;
  removeBottomMargin: boolean | undefined;
  removeMargins: boolean;
  classes: {
    searchPageContainer: string;
  };
};

const CurrentShopVehicleSelected = ({
  vehicle,
  currentShoppingVehicle,
  showYmmeWidget,
  onUpdateVehicleSuccess,
  closeYmmeWidget,
  handleShopWithoutVehicle,
  productRefinementIsAfterYMME,
  showYMMEMobileEnable,
  removeBottomMargin,
  removeMargins,
  classes,
}: CurrentShopVehicleSelectedProps) => {
  const locale = useLocale();

  const vehicleImage =
    vehicle && !!vehicle.vehicleImageURL ? (
      <NextImage
        src={vehicle.vehicleImageURL}
        alt=""
        height={28}
        width={60}
        className={styles.image}
      />
    ) : (
      <NextImage src={vehicleIconOrange.src} alt={vehicleIconOrange.alt} width={20} height={14} />
    );

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === eventConstants.enterKeyType || e.key === eventConstants.spaceKeyType) {
      showYmmeWidget();
    }
  };

  return (
    <>
      <Hidden mdUp>
        <div
          className={cx(
            styles.ymmeContainer,
            classes.searchPageContainer,
            productRefinementIsAfterYMME || removeBottomMargin ? styles.noMargin : '',
            {
              [styles.noMargins]: removeMargins,
            }
          )}
        >
          <div className={styles.ymmeTopContainer} id="YMMEBlockContainerMobile">
            <h2 className={cx(azCommonStyles['az-title-5-heavy'], styles.ymmeTitle)}>
              <Label label="label_ContentBlock_YMME_CurrentlyShoppingFor" />
            </h2>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={handleOnKeyDown}
              className={styles.ymmeMobileContainer}
              onClick={showYmmeWidget}
            >
              <div
                className={styles.name}
                id="currentVehicleName"
                data-testid="current-vehicle-mobile"
              >
                <span
                  className={cx(styles.noVehicleImageStyles, {
                    [styles.vehicleImageStyles]: vehicle && !!vehicle.vehicleImageURL,
                  })}
                >
                  {vehicleImage}
                </span>
                {currentShoppingVehicle}
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smDown>
        <div
          className={cx(
            styles.ymmeContainer,
            styles.ymmeTopContainer,
            styles.hasVehicle,
            classes.searchPageContainer,
            productRefinementIsAfterYMME || removeBottomMargin ? styles.noMargin : '',
            {
              [styles.noMargins]: removeMargins,
            }
          )}
          id="YMMEBlockContainerDesktop"
        >
          <div className={styles.titleContainer}>
            <h2
              id="ymme-title-selected"
              data-testid="ymme-title-selected"
              className={cx(
                azCommonStyles['az-title-5-medium'],
                styles.ymmeTitle,
                locale !== countryCodes.ptBr && styles.ymmeTitleMaxWidth
              )}
            >
              <Label label="label_ContentBlock_YMME_CurrentlyShoppingFor" />
            </h2>
          </div>
          <div
            role="button"
            tabIndex={0}
            className={styles.vehicleIconContainer}
            onClick={showYmmeWidget}
            onKeyDown={handleOnKeyDown}
          >
            <div
              className={cx(styles.vehicleIcon, {
                [styles.vehicleImage]: vehicle && !!vehicle.vehicleImageURL,
              })}
            >
              {vehicleImage}
            </div>
            <div
              className={styles.name}
              id="currentVehicleName"
              data-testid="current-vehicle-md-up"
            >
              {currentShoppingVehicle}
            </div>
          </div>
          <div className={styles.ymmeOptionsContainer}>
            <Button
              id="changeVehicleLink"
              variant="ghost"
              customClass={styles.vehicleLink}
              onClick={showYmmeWidget}
            >
              <Label label="hyperlink_YMME_Body_ChangeVehicle" />
            </Button>
            <Button
              id="shopWithoutVehicle"
              variant="ghost"
              customClass={styles.vehicleLink}
              onClick={handleShopWithoutVehicle}
            >
              <Label label="hyperlink_YMME_Body_ShopWithoutVehicle" />
            </Button>
          </div>
        </div>
      </Hidden>
      <YMMEModal
        showYmme={showYMMEMobileEnable}
        closeYmmeWidget={closeYmmeWidget}
        onUpdateVehicleSuccess={onUpdateVehicleSuccess}
      />
    </>
  );
};

type CurrentShoppVehicleLayoutProps = {
  ymmeTitle: React.ReactNode;
  ymmeDesc: React.ReactNode;
  pageType: string;
  makeOrModelName: string | null;
  showYmmeWidget: () => void;
  onUpdateVehicleSuccess: (() => void) | undefined;
  closeYmmeWidget: () => void;
  productRefinementIsAfterYMME: boolean;
  showYMMEMobileEnable: boolean;
  removeMargins: boolean;
  classes: {
    searchPageContainer: string;
    ymmeTitleLabel?: string;
    ymmeDescLabel?: string;
    titleContainerCustomStyle?: string;
  };
};

const CurrentShoppVehicleLayout = ({
  ymmeTitle,
  ymmeDesc,
  pageType,
  makeOrModelName,
  showYmmeWidget,
  onUpdateVehicleSuccess,
  closeYmmeWidget,
  productRefinementIsAfterYMME,
  showYMMEMobileEnable,
  removeMargins,
  classes,
}: CurrentShoppVehicleLayoutProps) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));

  const titleLabel = isMobile
    ? 'label_YMME_Body_WhatAreYouWorkingOnToday'
    : 'label_ymme_add_your_vehicle';
  const descriptionLabel = isMobile
    ? 'label_Add_your_vehicle_to_get_an_exact_fit'
    : 'hyperlink_YMME_Body_GetAnExactFitForYourVehicle';

  const ymmeTitleText = ymmeTitle || <Label label={titleLabel} />;
  const ymmeDescText = makeOrModelName ? (
    <>
      <Label label="label_YMME_GetAnExactFitForYour" />
      <strong className={styles.makeOrModelName}>{` ${makeOrModelName}`}</strong>
    </>
  ) : (
    ymmeDesc || <Label label={descriptionLabel} />
  );

  return (
    <ContentContainer withMargin={!removeMargins && !productRefinementIsAfterYMME}>
      <div
        className={cx(`${styles.ymmeContainer} ${classes.searchPageContainer}`, {
          [styles.noMargins]: removeMargins,
        })}
      >
        <div data-testid="ymme-top-container" className={styles.ymmeTopContainer}>
          <div
            className={cx(styles.titleContainer, styles.setVehicleTitle, {
              [classes.titleContainerCustomStyle || '']: classes.titleContainerCustomStyle,
            })}
          >
            <h2
              id="ymme-title-not-selected"
              data-testid="ymme-content-title"
              className={cx(
                isMobile ? azCommonStyles['az-title-5-heavy'] : azCommonStyles['az-title-5-medium'],
                styles.ymmeTitle,
                { [classes.ymmeTitleLabel || '']: classes.ymmeTitleLabel }
              )}
            >
              {ymmeTitleText}
            </h2>
            <div
              className={cx(azCommonStyles['az-body-1-regular'], styles.titleDesc, {
                [classes.ymmeDescLabel || '']: classes.ymmeDescLabel,
              })}
            >
              {ymmeDescText}
            </div>
          </div>
          {/* hidden cannot be implementation css here because the two ymme instances conflict */}
          <Hidden implementation="js" mdUp>
            <Button
              variant="ghost"
              customClass={styles.selectVehicle}
              onClick={showYmmeWidget}
              fullWidth
            >
              <div className={styles.carIcon}>
                <NextImage src="/images/vehicle/light-gray.svg" alt="" width={16} height={12} />
              </div>

              <span className={cx(azCommonStyles['az-body-2-regular'], styles.vehicleLabel)}>
                <Label label="hyperlink_ShelfPage_body_AddAVehicle" />
              </span>
              <div className={styles.downIcon}>
                <NextImage
                  src={itemDetail.downArrow.src}
                  alt={itemDetail.downArrow.alt}
                  width={13}
                  height={13}
                />
              </div>
            </Button>
            <YMMEModal
              showYmme={showYMMEMobileEnable}
              closeYmmeWidget={closeYmmeWidget}
              onUpdateVehicleSuccess={onUpdateVehicleSuccess}
            />
          </Hidden>
          <div className={styles.desktopWrapper}>
            {/* hidden cannot be implementation css here because the two ymme instances conflict */}
            <Hidden implementation="js" smDown>
              <div data-testid="ymme-test" className={styles.selectContainer}>
                <YmmeSelectComponent
                  pageType={pageType}
                  onUpdateVehicleSuccess={onUpdateVehicleSuccess}
                />
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    </ContentContainer>
  );
};
