/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  YMMEContentBlock,
  type YMMEContentBlockProps,
} from '@/features/ymme/components/YMMEContentBlock';
import AltYMMEContentBlock from '@/features/ymme/components/AltYMMEContentBlock';
import { type ContentStackYmme } from '../../interface';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { pageTypes } from '@/constants/page';

type Props = {
  content: Omit<ContentStackYmme, 'theme' | 'title'>;
  redirectAfterVehicleChanged?: YMMEContentBlockProps['onUpdateVehicleSuccess'];
  removeBottomMargin?: boolean;
  removeMargins?: boolean;
  withPadding?: boolean;
  classes?: YMMEContentBlockProps['classes'];
  isProductFinderDisplayed?: boolean;
  nonShopPage?: boolean;
  pageType?: string;
};

export function CMSYmme({
  classes,
  content,
  redirectAfterVehicleChanged,
  removeBottomMargin,
  removeMargins,
  withPadding,
  isProductFinderDisplayed,
  pageType,
  nonShopPage = false,
}: Props) {
  const { layout, text, sub_text } = content;
  const altYMMEContentBlock = useMonetateDecisionFlag('alternativeYMMEContentBlock');

  const layoutType = layout?.toLowerCase() as Lowercase<NonNullable<ContentStackYmme['layout']>>;

  return (
    <>
      {altYMMEContentBlock ? (
        <AltYMMEContentBlock nonShopPage={nonShopPage} pageType={pageType} />
      ) : (
        <YMMEContentBlock
          ymmeTitle={text}
          ymmeDesc={sub_text}
          layoutType={layoutType}
          onUpdateVehicleSuccess={redirectAfterVehicleChanged}
          classes={classes}
          removeBottomMargin={removeBottomMargin}
          removeMargins={removeMargins}
          withPadding={withPadding}
          isProductFinderDisplayed={isProductFinderDisplayed}
          pageType={pageTypes.shelf}
        />
      )}
    </>
  );
}
